import React from "react"
import { Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/blog-layout"
import SEO from "../components/seo"
import BlogAuthor from "../components/blog-author"

// Styles
import "../styles/pages/blog.scss"

const BlogPage = ({ data }) => {
  const { allContentfulBlogPost } = data
  const [firstPost, ...restOfPosts] = allContentfulBlogPost.edges
  return (
    <Layout pageName="blog" noHero>
      <SEO title="Twispay - About Payments" />
      <section className="section ">
        <div className="container">
          <h1 className="title has-text-centered">
            The Best FinTech Articles Every Week
          </h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-full">
              <div className="is-card ">
                <div className="columns first-post">
                  <div className="column is-three-fifths thumbnail-wrapper">
                    <Link to={`/blog/${firstPost.node.slug}`}>
                      <BackgroundImage
                        Tag="div"
                        className="thumbnail"
                        fluid={firstPost.node.headerImage.fluid}
                      />
                    </Link>
                  </div>
                  <div className="column">
                    <div className="is-flex is-vspaced post-content">
                      <h2 className="title is-5">
                        <Link to={`/blog/${firstPost.node.slug}`}>
                          {firstPost.node.title}
                        </Link>
                      </h2>
                      <div>
                        <BlogAuthor
                          bgImg={firstPost.node.author.image.fixed.src}
                          name={firstPost.node.author.name}
                          className="has-margin-bottom-3"
                        />
                        <p className="has-margin-bottom-5">
                          {firstPost.node.shortDescription}
                        </p>
                        <Link
                          to={`/blog/${firstPost.node.slug}`}
                          className="button is-success is-fullwidth"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {restOfPosts.map(post => (
              <div key={post.node.id} className="column is-one-third">
                <div className="is-card">
                  <div className="thumbnail-wrapper">
                    <Link to={`/blog/${post.node.slug}`}>
                      <BackgroundImage
                        Tag="div"
                        className="thumbnail"
                        fluid={post.node.headerImage.fluid}
                      />
                    </Link>
                  </div>
                  <div className="post-content is-flex is-vspaced">
                    <h2 className="title is-5">
                      <Link to={`/blog/${post.node.slug}`}>
                        {post.node.title}
                      </Link>
                    </h2>
                    <div>
                      <BlogAuthor
                        bgImg={post.node.author.image.fixed.src}
                        name={post.node.author.name}
                        className="has-margin-bottom-3"
                      />
                      <p className="has-margin-bottom-5">
                        {post.node.shortDescription}
                      </p>
                      <Link
                        to={`/blog/${post.node.slug}`}
                        className="button is-success is-fullwidth"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="has-text-centered has-margin-bottom-6">
            <Link to="/blog/page/2" className="button is-primary is-small">
              See More articles
            </Link>
          </div>
          {/* <div className="is-card newsletter-wrapper">
            <div className="columns is-vcentered">
              <div className="column is-flex">
                <span className="icon is-large has-margin-right-4 has-text-primary">
                  <BoxIcon />
                </span>
                <div>
                  <h3 className="title is-5">
                    Get the best finance articles everyday
                  </h3>
                  <p className="subtitle has-text-grey-light is-6">
                    The future of money starts here
                  </p>
                </div>
              </div>
              <div className="column">
                <form
                  name="Newsletter"
                  method="POST"
                  data-netlify="true"
                  action="/newsletter-success"
                >
                  <input type="hidden" name="form-name" value="Newsletter" />
                  <div className="field">
                    <label className="label" htmlFor="email">
                      Name
                    </label>
                    <div className="control is-flex">
                      <input
                        className="input"
                        type="email"
                        name="Email"
                        id="email"
                        placeholder="example@domain.com"
                        required
                      />
                      <button type="submit" className="button is-success">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(
      sort: { fields: [createdAt], order: DESC }
      limit: 7
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt
          shortDescription
          author {
            name
            image {
              fixed(width: 120) {
                src
              }
            }
          }
          headerImage {
            fluid(maxHeight: 800) {
              base64
              aspectRatio
              src
              sizes
              srcSet
            }
          }
        }
      }
    }
  }
`

export default BlogPage
